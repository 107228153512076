<template>
  <div>
    <base-calendar
      :content="events"
      @onEventClicked="onTaskSelected"
      @onEventRightClicked="onTaskRightClicked"
      @calendarContextAction="onCalendarContextAction"
      :context-menu-items="projectCalendarItems"
    ></base-calendar>

    <project-task-data-dialog
      :project="project"
      :task="task"
      :visible="visibleDialogTaskData"
      @close="closeTaskDataDialog"
    >
    </project-task-data-dialog>

    <!-- Show Report Dialog -->
    <project-detailed-report-dialog
      :project="project"
      :visible="visibleDialogProjectExport"
      @close="closeDialogExportProject"
    ></project-detailed-report-dialog>
  </div>
</template>

<script>
import {
  getProjectTasksCalendarEvents,
  projectCalendarMenuItems,
  projectContextMenuItem
} from "@/services/project/projectService";
import { projectNavigatable } from "@/mixins/shared/navigatable/projectNavigatable";
import { folderRecordNavigatable } from "@/mixins/shared/navigatable/folderRecordNavigatable";
import { userMixin } from "@/mixins/shared/user/userMixin";

export default {
  name: "ProjectTaskCalendar",
  mixins: [projectNavigatable, folderRecordNavigatable, userMixin],
  components: {
    ProjectTaskDataDialog: () =>
      import("@/components/project/dialogs/ProjectTaskDataDialog.vue"),
    BaseCalendar: () => import("@/components/shared/base/BaseCalendar.vue"),
    ProjectDetailedReportDialog: () =>
      import("@/components/project/dialogs/ProjectDetailedReportDialog.vue")
  },
  props: {
    project: undefined,
    /**
     * project's record
     * @type {{id:number, name:string, categoryId:number, recordTypeId:number, recordType: string, parentId:number, children:number, createdBy:string, creationDate:string, extension:string, isComposite:boolean, isLink:boolean, isReadOnly:boolean, isComposite: boolean, isDeleted: boolean, isDraft: boolean, isLink: boolean, isLocked: boolean, stateId:number, state:string, fieldValues: {id: number, name:string, fieldDataType: number, fieldDataTypeName: string, value: string}[], flyingFields: {id:number, sequence:number}[]}}
     */
    record: undefined
  },
  data() {
    return {
      task: undefined,
      visibleDialogTaskData: false,
      projectCalendarItems: undefined,
      visibleDialogProjectExport: false
    };
  },
  computed: {
    events() {
      return getProjectTasksCalendarEvents(this.project) ?? [];
    }
  },

  methods: {
    /**
     * Event on Task clicked
     * @param taskId
     */
    onTaskSelected(taskId) {
      this.task = this.project.userTasks.find(task => task.taskId === taskId);
      this.visibleDialogTaskData = true;
    },

    /**
     * Event on Task Right Clicked
     * @param payload
     */
    onTaskRightClicked(payload) {
      const task = this.project.userTasks.find(
        task => task.taskId === payload.id
      );
      this.projectCalendarItems = projectCalendarMenuItems(
        task,
        this.principal?.actorId ?? -1,
        payload.calendarType
      );
    },

    /**
     * Close Task Data Dialog
     */
    closeTaskDataDialog() {
      this.visibleDialogTaskData = false;
    },

    /**
     * Event on Context Menu Action
     * @param payload
     */
    onCalendarContextAction(payload) {
      try {
        switch (payload.action.name) {
          case projectContextMenuItem.ShowDetails:
            this.onTaskSelected(payload.id);
            break;

          case projectContextMenuItem.JumpToTask:
            this.$router.push(this.taskRouteLocation(payload.id));
            break;

          case projectContextMenuItem.JumpToRecord:
            this.$router.push(
              this.folderModuleRecordRouteLocation(this.record)
            );
            break;

          case projectContextMenuItem.Export:
            this.visibleDialogProjectExport = true;
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * handle close Dialog Export Project
     */
    closeDialogExportProject() {
      this.visibleDialogProjectExport = false;
    }
  }
};
</script>
